
import About from '@/pages/about/index'
import Contact from '@/pages/contact/index'
import Gallary from '@/pages/gallary/index'
import Gallarys from '@/pages/gallarys/index'
const routers = [
  {
    title: 'Contact',
    path: '/',
    component: Contact
  },
  {
    title: 'About',
    path: '/about',
    component: About
  },
  {
    title: 'Gallary',
    path: '/gallary',
    component: Gallary
  },
  {
    title: 'Gallarys',
    path: '/gallarys',
    component: Gallarys
  },
];

export default routers
