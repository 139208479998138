import axios from "./request"

export function get_category() {
    return axios({
      url: '/api/NFT/category',
      method: 'get',
    })
}
export function get_nftlist() {
    return axios({
      url: '/api/NFT/list/?tag=&pageNum=1&pageSetNum=12&zooName=&typeEnum=0',
      method: 'get',
    
    })
}
export function get_nftlistselect(params) {
  return axios({
    url: '/api/NFT/list',
    method: 'get',
    params:params
  })
}
