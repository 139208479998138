import axios from "axios";

const axiosOption={
   baseURL:window.domain,
   // baseURL:"/api",
   // timeout:1000,
}
const instance=axios.create(axiosOption)
instance.interceptors.request.use(
   function(config){
    return config;
   },
   function(error){
    return Promise.reject(error)
   }
   
)
instance.interceptors.request.use(
    function(response){
     return response;
    },
    function(error){
     return Promise.reject(error)
    }
    
 );
 export default instance;