import React, {Component} from 'react'
import { Link, useLocation  } from 'react-router-dom'

import { UnorderedListOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import './index.scss'
import Logo from '@/assets/images/logo.png'
import Bird from '@/assets/images/Bird.png'
import Camera from '@/assets/images/Camera.png'

const items = [
    {
        label: <Link to="/gallarys">GALLERYS</Link>,
        key: '0',
    },
    {
        label: <Link to="/about">ABOUT</Link>,
        key: '1',
    },
    {
        label: <Link to="/">CONTACT</Link>,
        key: '2',
    }
    
  ];
export default class Header extends Component {
    state = {
        currentNavIndex: null,
        navList: [
            { lable: 'GALLERYS', value: 'gallarys'},
            { lable: 'ABOUT', value: 'about'},
            { lable: 'CONTACT', value: ''}
        ],
        T:"https://twitter.com/ZooClubOfficial",
       
    }

    componentDidMount() {

    }
    links(){
        window.open(this.state.T,'_blank')
    } 
    imghome(){
        window.location.href="https://zoo.club/"
    }
    render() {
        const { navList, currentNavIndex } = this.state
        return (
            <div className="club-header Horizontal_Layout">
                <div className="club-header-l Horizontal_Layout">
                    <Dropdown
                        className='drop-down visible-xs'
                        menu={{
                        items,
                        }}
                        trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <UnorderedListOutlined />
                        </Space>
                        </a>
                    </Dropdown>
                    {/* <Link to="/"><img src={Logo} alt="" /></Link> */}
                    <img onClick={()=>this.imghome()}  src={Logo} alt="" />
                    
                </div>
                <div className="club-header-r Horizontal_Layout">
                    <ul className='hidden-xs'>
                        {
                            navList.map((item, index) => (
                                <li onClick={() => {this.setState({currentNavIndex: index})}} className={`${currentNavIndex === index ? 'active' : ''}`} key={index}>
                                    <Link to={item.value} className='link'>{item.lable}</Link>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="icon" style={{display:"flex"}}>
                        <div className='linkli'>
                        <p className='linkt' >ZooClubOfficial</p>
                        <img style={{cursor: "pointer"}} onClick={()=>this.links()} src={Bird} alt="" /></div>
                        <img src={Camera} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}