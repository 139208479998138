import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import routers from '@/routers/index';
import Header from '@/component/Header'

function getRem(){
	let designSize = 1920;
	let html = document.documentElement;
	let clientW = html.clientWidth;
	let htmlRem = clientW * 100 / designSize;
	html.style.fontSize = htmlRem + 'px';
}

window.addEventListener('load', function(){
  getRem()
})
window.addEventListener('resize',function(){
  getRem()
})

function App() { 
  useEffect(() => {
    getRem()
  }, [])
  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          {routers.map((item, index) => {
            return (
              <Route
                key={index}
                exact
                path={item.path}
                element={<item.component />}
              />
            )
            })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
