import React, { Component } from 'react'
import '@/assets/styles/gallarys.scss'

import { Checkbox, Modal, Pagination ,Spin,Input,Select,} from 'antd'  //Radio, Space,
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import Banner from '@/assets/images/banner.png'
import Down from '@/assets/images/down.png'
import Up from '@/assets/images/up.png'
import { get_category, get_nftlist, get_nftlistselect } from '@/assets/api/api'


const { Option } = Select;


export default class Gallarys extends Component {
    state = {
        currentIndex: 0,
        currentFilterIndex: 0,
        showRadioBox: true,
        showLoading: true,
        showModal: false,
        checked: true,
        pagenum: 1,
        cate: [],
        nftlist: [],
        showimg: false,
        amplificationdetail: '',
        amplificationname: "",
        amplificationlink: "",
        amplificationlinks: '',
        page: 1,
        loading: true,
        selectlist:0,
        value:'',
        selectedlists:[],
        pageSetNum: 12,
        selectname:"ALL",
        fsecondCity:"0",
       

    }

    componentDidMount() {
        this.setcate()
        this.setnftlist()
    }
    componentWillUnmount() {
        this.getcatepush()
    }
    //左侧列表接口
    setcate() {
        get_category().then(({ data }) => {
            this.setState({ cate: data.Result.tag })
        })
        this.state.cate.forEach((value, index) => {
            value['selected'] = []

        })
    }
    getcatepush() {
       
        let obj = []
        this.state.cate.forEach((item, index) => {
            obj[index] = 0
            item.attributes.forEach(i => {
                obj[index] = i['checked'] = false

            })

        })

    }

    //右侧图片接口
    setnftlist() {

        get_nftlist().then(({ data }) => {

            if (data) {

                this.setState({
                    loading: false,

                    nftlist: data
                })

            }


        })
    }
    // 侧边栏条件展开
    handleTitleSwitch(index) {

        this.setState(state => {
            // this.state.cate.forEach((value, index) => {
            //     value['isOpen'] = false

            // })
            state.cate.forEach((value, index) => {
                if (value.hasOwnProperty("selected")) {
                    Object.assign(value, { selected: this.state.cate[index].selected })
                } else {
                    Object.assign(value, { selected: [] })
                }
            })

            return {

                currentFilterIndex: index,
                cate: state.cate.map((item, key) => {
                    return { ...item, isOpen: key === index ? !item.isOpen : false }
                })

            }
        }, () => {

            //  console.log('点击侧边栏', this.state.cate)
        })
    }

    // 多选按钮
    onCheckChangeModel = (e, index, checkIndex) => {
        this.setState({pagenum:1,loading: true,nftlist:[]})
       
        let obj = JSON.parse(JSON.stringify(this.state.cate))

        obj[index].attributes[checkIndex].checked = !obj[index].attributes[checkIndex].checked
        if(e.target.checked){
            obj[index].selected.push({ attribute: e.target.value })
        } else {
            const newList = obj[index].selected.filter(item => item.attribute !== e.target.value)
            obj[index].selected = newList
        }

        this.setState(state => {
            return {
                cate: obj
            }
        }, () => {
           

            let list = this.state.cate
            list.forEach((item, index) => {
                if (item.selected.length > 0) {
                    item.selected.forEach(value => {
                        value['category'] = item.tag_name

                    })
                }
            })

            let selectedlist = []
            list.filter((item, index) => {
                if (item.selected.length > 0) {

                    selectedlist.push(item.selected)


                }
            })
            this.state.selectedlists = [].concat(...selectedlist)
         //  console.log(this.state.selectedlists,"this.state.selectedlists")
            const param = {
                pageNum: this.state.page,
                pageSetNum: this.state.pageSetNum,
                zooName: null,
                typeEnum: this.state.selectlist,
                tag: JSON.stringify({ "tag": this.state.selectedlists })
            }

            get_nftlistselect(param).then(({ data }) => {

                if (data) {
                    this.setState({
                        nftlist: data,
                        loading: false,
                        page:"1",
                    })
                }
            })
        })
    };
    //点击放大
    handleimg(index) {
             if(this.state.nftlist.Result[index].AliasName===''){
               this.state.amplificationdetail= this.state.nftlist.Result[index].ArImageUrl
            }else{
                this.state.amplificationdetail="https://zoo.club/images/arImage/arImage3ReNameAsNo/"+this.state.nftlist.Result[index].AliasName
            }
        this.setState({
            amplificationname: this.state.nftlist.Result[index].ZooName,
            amplificationlink: this.state.nftlist.Result[index].NftId,
            showimg: true
        });

    }
    showimgs() {
        this.setState({
            showimg: false
        });

    }
    imglink() {
      //  this.state.amplificationlinks = this.state.nftlist.Result[index].NftId
        window.open('https://www.spacescan.io/nft/' + this.state.amplificationlink)
    }
    imglinks(index) {

        this.state.amplificationlinks = this.state.nftlist.Result[index].NftId

        window.open('https://www.spacescan.io/nft/' + this.state.amplificationlinks)
    }


    // 重置
    resetFilter = () => {
       
       this.setState({pagenum:1,loading: true,selectedlists:[],fsecondCity:"0"})
        let list = this.state.cate
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].attributes.length; j++) {
                list[i].attributes[j].checked = false
                list[i].selected = []
            }
        }
        //console.log(list,"list")
        this.setState(state => {
            return {
                cate: list,
                showModal: false,
                selectedlists:[],
                selectlist:0,
                selectname:'ALL'
            }
        }, () => {
             this.setState({value: "",selectedlists:[],selectlist:0,selectname:'ALL'})
            this.setnftlist()
         
        })
        


    }
    //分页
    pageHandle = (page) => {
      
       this.setState({
        loading: true,
        pagenum: page,
        nftlist:[]
    })
             const param = {
                pageNum: page,
                pageSetNum: this.state.pageSetNum,
                zooName: null,
                typeEnum: this.state.selectlist,
                tag: JSON.stringify({ "tag": this.state.selectedlists })
            }

            get_nftlistselect(param).then(({ data }) => {

                if (data) {
                    this.setState({
                        nftlist: data,
                        loading: false
                        
                    })
                }
                 
            })
       
       

    }
   
    handleChange(value){
       
       
       this.state.selectlist=value
     
       if(value==="1"){
         this.setState({fsecondCity:"1"})
       }else if(value==="2"){
        this.setState({fsecondCity:"2"})
       }else if(value==="3"){
        this.setState({fsecondCity:"3"})
       }else if(value==="0"){
        this.setState({fsecondCity:"0"})
       }
       
    }
    onChange(e){
        this.setState({
            value:e.target.value
        })
    }
     //搜索框
    onSearch(value) {
        
        this.setState({pagenum:1,loading: true,})
        const param = {
            pageNum: this.state.page,
            pageSetNum: this.state.pageSetNum,
            zooName: value,
            typeEnum: this.state.selectlist,
            tag: JSON.stringify({ "tag": this.state.selectedlists })
        }
        get_nftlistselect(param).then(({ data }) => {
            console.log(data,"data")
            if (data) {
                this.setState({
                    loading: false,

                    nftlist: data
                })
            }
        })
    
   
    }
  
    // 移动端
    handleOpenModal = () => {
        this.setState({ showModal: true })
    }
    // 多选按钮
    onCheckChangeModels = (e, index, checkIndex) => {
       
        this.setState({pagenum:1})
        let obj = JSON.parse(JSON.stringify(this.state.cate))
     
        obj[index].attributes[checkIndex].checked = !obj[index].attributes[checkIndex].checked
        if(e.target.checked){
            obj[index].selected.push({ attribute: e.target.value })
        } else {
            const newList = obj[index].selected.filter(item => item.attribute !== e.target.value)
            obj[index].selected = newList
        }
        this.setState(state => {
            return {
                cate: obj
            }
        }, () => {
            // console.log('侧边栏勾选框的值selected属性', this.state.cate)
            let list = this.state.cate
            list.forEach((item, index) => {
                if (item.selected.length > 0) {
                    item.selected.forEach(value => {
                        value['category'] = item.tag_name
    
                    })
                }
            })
    
            let selectedlist = []
            list.filter((item, index) => {
                if (item.selected.length > 0) {
    
                    selectedlist.push(item.selected)
    
    
                }
            })
            this.state.selectedlists = [].concat(...selectedlist)

        })
    };
    handleOkModal = () => {
        this.setState({pagenum:1,loading: true,nftlist:[]})
      

        const param = {
            pageNum: 1,
            pageSetNum: this.state.pageSetNum,
            zooName: null,
            typeEnum: this.state.selectlist,
            tag: JSON.stringify({ "tag":  this.state.selectedlists })
        }

        get_nftlistselect(param).then(({ data }) => {

            if (data) {
                this.setState({
                    nftlist: data,
                    loading: false
                })

            }
        })
        this.setState({ showModal: false })
    }
    handleCancelModal = () => {
        this.setState({ showModal: false })
    }

    render() {
        let { showModal, cate, nftlist, showimg, loading, value: val ,fsecondCity} = this.state  // showRadioBox, radioData, 
        return (
            <div className="gallary-page">

                <div>
                    <div style={{width:"100%",padding:"0 4%"}}>

                    
                    <div className='selectlist visible-xs'>
                        <Input.Group compact>
                            <Select onChange={(value) => this.handleChange(value)} value={fsecondCity} defaultValue="ALL" style={{ width: '30%' }}>
                                <Option value="0">ALL</Option>
                                <Option value="1">Low rarity</Option>
                                <Option value="2">Medium rarity</Option>
                                <Option value="3">High rarity</Option>
                            </Select>
                            <Input.Search allowClear style={{ width: '70%' }} placeholder="Please enter Zoo Name" value={val}  onSearch={(value)=>this.onSearch(value)} onChange={(e)=>{this.onChange(e)}} />


                        </Input.Group>
                     
                    </div></div>
                    <div className="gallary-title Horizontal_Layout">
                        
                        <div>
                            {
                                // this.state.cate
                                // tabData.map((item, index) => (
                                //     <div onClick={ () => this.handleTabSwitch(index) } key={index}
                                //         className={ `tab-title ${index === currentIndex ? 'active' : ''}` }>
                                //         <img src={require( `@/assets/images/${index === currentIndex ? item.titleActive : item.title}`)} alt="" />
                                //     </div>
                                // ))
                            }
                        </div>
                        <span className='filter visible-xs' onClick={this.handleOpenModal} >FILTER<DownOutlined /></span>
                        <Modal maskTransitionName="" transitionName=""
                            className='filter-modal' title="Basic Modal" open={showModal} onOk={this.handleOkModal} onCancel={this.handleCancelModal}>
                            <ul className='checkBox'>
                                {
                                    cate.map((item, index) => (
                                        <li key={index}>
                                            <div className='gallary-filter-title Horizontal_Layout'
                                                onClick={() => this.handleTitleSwitch(index)} >
                                                <div>
                                                    <span>{item.en_name}</span>
                                                </div>
                                                <UpOutlined className={`arrow ${item.isOpen ? '' : 'hide'}`} />
                                                <DownOutlined className={`arrow ${item.isOpen ? 'hide' : ''}`} />
                                            </div>
                                            <div className={`gallary-page-l-check ${item.isOpen ? 'active' : ''}`}>
                                                {
                                                    item.attributes.map((check, checkIndex) => (

                                                        
                                                        <Checkbox   className='activeshow' value={check.en_name} key={checkIndex}
                                                            checked={check.checked}
                                                            onChange={e => this.onCheckChangeModels(e, index, checkIndex)}>
                                                            {check.en_name}
                                                        </Checkbox>
                                                    ))
                                                }

                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className='reset-btn' onClick={() => this.resetFilter()}>
                                RESET FILTERS
                            </div>
                            {/* <div className='tag-select'>
                                <div className='gallary-filter-title Horizontal_Layout' onClick={ () => this.openRadioBox() }>
                                    <div>
                                        <span>EAR</span>
                                    </div>
                                    <UpOutlined className={`arrow ${showRadioBox ? '' : 'hide'}`}/>
                                    <DownOutlined className={`arrow ${showRadioBox ? 'hide' : ''}`}/>
                                </div>
                                <Radio.Group onChange={(e) => this.onRaioChange(e) } className={`tag-select-radio ${showRadioBox ? '' : 'hide'}`}>
                                    <Space direction="vertical">
                                        {
                                            radioData.map((item, index) => (  
                                                <Radio value={item.value} key={index}>{item.lable}</Radio>
                                            ))
                                        }
                                    </Space>
                                </Radio.Group>
                            </div> */}
                        </Modal>
                    </div>
                    <div className='Horizontal_Layout'>
                        <div className="gallary-page-l hidden-xs">
                        <div className='selectlist'>
                        <Input.Group compact>
                            <Select onChange={(value) => this.handleChange(value)} value={fsecondCity}  defaultValue={this.state.selectname} style={{ width: '30%' }}>
                                <Option value="0">ALL</Option>
                                <Option value="1">Low rarity</Option>
                                <Option value="2">Medium rarity</Option>
                                <Option value="3">High rarity</Option>
                            </Select>
                            <Input.Search allowClear style={{ width: '70%' }} placeholder="Please enter Zoo Name" value={val}  onSearch={(value)=>this.onSearch(value)} onChange={(e)=>{this.onChange(e)}} />


                        </Input.Group>
                     
                    </div>
                    <span className='filter hidden-xs'>FILTER</span>
                            <ul className='checkBox'>
                                {


                                    cate.map((item, index) => (
                                        <li key={index}>

                                            <div className='gallary-filter-title Horizontal_Layout'
                                                onClick={() => this.handleTitleSwitch(index)} >
                                                <div>
                                                    {/* <img  src={require( `@/assets/images/${item.icon}`)} alt="" /> */}
                                                    <span>{item.en_name}</span>
                                                </div>
                                                <img className='arrow' src={item.isOpen ? Up : Down} alt="" />
                                            </div>
                                            <div className={`gallary-page-l-check ${item.isOpen ? 'active' : ''}`}>
                                                {
                                                    item.attributes.map((check, checkIndex) => (
                                                       
                                                        <Checkbox value={check.en_name} key={checkIndex}
                                                            checked={check.checked}
                                                            onChange={e => this.onCheckChangeModel(e, index, checkIndex)}>
                                                            {check.en_name}
                                                        </Checkbox>
                                                    )
                                                    )
                                                }
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className='reset-btn' onClick={() => this.resetFilter()}>
                                RESET FILTERS
                            </div>

                        </div>
                        <div className="gallary-page-r" >
                            <img className='gallary-page-r-banner' src={Banner} alt="" />
                            {
                                loading ?
                                    <div className='loading-box'> 
                                     
                                      <Spin tip="Loading" size="large">
                                        <div className="content" style={{fontSize:"0.2rem"}}/>
                                      </Spin>
                                   
                                  
                                </div> :
                                    <div className='tab-content' >
                                        <div className='tab-content-imgBox'>
                                            
                                            {
                                                 
                                                nftlist.Result.length!==0 ?
                                                    nftlist.Result.map((item, index) => (
                                                      <div key={index} className={` ${window.innerWidth > 768 ? 'tab-content-imgBox-web' : 'tab-content-imgBox-app'}`} style={{ position: 'relative', padding: "0.04rem", }}>
                                                            <div className='tab-content-box'>

                                                                {
                                                                    item.AliasName===''?
                                                                    <img onClick={() => this.handleimg(index)} src={item.ArImageUrl} alt="" />:
                                                                    <img onClick={() => this.handleimg(index)} src={'https://zoo.club/images/arImage/arImage3ReNameAsNo/'+item.AliasName} alt="" />
                                                                }
                                                                
                                                                <p >{item.ZooName}</p>

                                                                {
                                                                    window.innerWidth < 768 ? null :

                                                                        <div className='tab-content-imgBox-change' >
                                                                            {/* <EllipsisOutlined   style={{ fontSize: '0.3rem', color: '#F56702', }}/> */}
                                                                            <ul>
                                                                                <li onClick={() => this.handleimg(index)}>Enlarge Image</li>
                                                                                <li onClick={() => this.imglinks(index)}>LINK TO SPACESCAN.IO</li>
                                                                            </ul>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    )) : <div className='tab-content-imgBox-no'>No related collection has been found</div>
                                            }

                                        </div>
                                        {
                                            nftlist.Counts > 0 ?
                                                (<div className={`${window.innerWidth > 768 ? 'h-pagination' : "h-paginations"}`}>
                                                    <p></p>
                                                    <div className='h-pagination-web'>
                                                     <p style={{fontSize:"18px",color:"#fff"}}>Total:{nftlist.Counts}</p>
                                                    <Pagination
                                                        size='0.5rem'
                                                        current={this.state.pagenum}
 
                                                        total={nftlist.Counts}
                                                        pageSize={12}
                                                        onChange={this.pageHandle}
                                                    /></div>
                                                   <p></p>
                                                </div>) : ('')
                                            }


                                        {/* 放大蒙层 */}
                                        {showimg ? (
                                            <div className='tab-amplification' onClick={() => this.showimgs()}>
                                               { window.innerWidth > 768 ?  
                                                <div className='tab-amplificationdetail'>
                                                    <div className='tab-amplificationdetail-app-big'>
                                                         <img style={{ height: '100%', borderRadius: '10px' }} src={this.state.amplificationdetail} alt="" />
                                                        <p>{this.state.amplificationname}</p>
                                                         <p onClick={() => this.imglink()}>LINK TO SPACESCAN.IO</p>
                                                    </div>
                                              
                                               </div>
                                               :
                                                <div className='tab-amplificationdetail-app'>
                                                    <div className='tab-amplificationdetail-app-big'>
                                                         <img style={{ width: '15rem', height: '15rem', borderRadius: '10px' }} src={this.state.amplificationdetail} alt="" />
                                                        <p>{this.state.amplificationname}</p>
                                                        
                                                    </div>
                                               <p onClick={() => this.imglink()}>LINK TO SPACESCAN.IO</p>

                                            </div>
                                               }
                                               
                                            </div>
                                        ) : null
                                        }



                                    </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}