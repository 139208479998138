import React, {Component} from 'react'
import '@/assets/styles/about.scss'


// import TextBorder from '@/assets/images/textBorder.png'
import BigPanada from '@/assets/images/BigPanada.png'
export default class About extends Component {
    render() {
        return (
            <div className="about-page Horizontal_Layout">
                <div className="about-page-l">
                    <p>
                    In 2019, humans experimented with in vivo artificial intelligence in animals, leading to the awakening of some of them. 
                    <br/><br/>
                    2050, the awakened animals began to unite against humans, competing for the Earth's already limited resources. 
                    <br/><br/>
                    2220, the end of the Great War, the end of humanity, the Earth's environment was also damaged by the war extension. After the complete awakening of the animal alliance began to explore the development of outer space.
                    <br/><br/>
                    ......
                    </p>
                </div>
                <div className="about-page-r">
                    <img src={BigPanada} alt="" />
                </div>
            </div>
        )
    }
}