import React, { Component } from 'react'
import '@/assets/styles/gallary.scss'

import { Checkbox,  Modal } from 'antd'  //Radio, Space,
    import { DownOutlined, UpOutlined  } from '@ant-design/icons'

import Banner from '@/assets/images/banner.png'
import Down from '@/assets/images/down.png'
import Up from '@/assets/images/up.png'
// import Tu from '@/assets/images/tu.png'
// import TuArrowUp from '@/assets/images/tu-arrow-up.png'
// import TuArrowDown from '@/assets/images/tu-arrow-down.png'


export default class Gallary extends Component {
    state = { 
        currentIndex : 0,
        currentFilterIndex : 0,
        showRadioBox: true,
        showLoading: true,
        showModal: false,
        checked: true,
        tabData: [
            {
                title: 'PANDA.png',
                titleActive: 'PANDA-active.png',
                showLoading: false,
                pageSize: 20,  // 每次返回十条数据
                pageNum: 1,    // 当前页数
                total: 0,      // 总条数
                imgUrl: []
            },
            {
                title: 'CATTLE.png',
                titleActive: 'CATTLE-active.png',
                showLoading: false,
                pageSize: 20,  
                pageNum: 1,    
                total: 0,    
                imgUrl: []
            }
        ],
        listFilterData: [
            {
                icon: 'icon1.png',
                title: 'FILTER',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Black and White", lable: "Black and White", checked: false },
                    { value: "Leopard pattern", lable: "Leopard pattern", checked: false },
                    { value: "Deer pattern", lable: "Deer pattern", checked: false },
                    { value: "Dazzling color", lable: "Dazzling color", checked: false },
                    { value: "Pink", lable: "Pink", checked: false },
                    { value: "Blue", lable: "Blue", checked: false },
                    { value: "Camouflage", lable: "Camouflage", checked: false },
                    { value: "Purple", lable: "Purple", checked: false },
                    { value: "Brown", lable: "Brown", checked: false },
                    { value: "Green", lable: "Green", checked: false },
                    { value: "Invert Color", lable: " Invert Color", checked: false }
                ]
            },
            {
                icon: 'icon2.png',
                title: 'CLOTHING',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Big gold necklace", lable: "Big gold necklace", checked: false },
                    { value: "Big wave dot shirt", lable: "Big wave dot shirt", checked: false },
                    { value: "Black and white attendant clothes", lable: "Black and white attendant clothes", checked: false },
                    { value: "Black armoured cloth", lable: "Black armoured cloth", checked: false },
                    { value: "Black battle suit with blue shoulder amor", lable: "Black battle suit with blue shoulder amor", checked: false },
                    { value: "_Black Bruce Lee's Clothes", lable: "_Black Bruce Lee's Clothes", checked: false },
                    { value: "Black casual coat", lable: "Black casual coat", checked: false },
                    { value: "Black combat suit", lable: "Black combat suit", checked: false },
                    { value: "Black Combat Vest", lable: "Black Combat Vest", checked: false },
                    { value: "Black gentleman suit", lable: "Black gentleman suit", checked: false },
                    { value: "Black Gladiator suit", lable: "Black Gladiator suit", checked: false },
                    { value: "Black JK uniform", lable: "Black JK uniform", checked: false },
                    { value: "Black kimono", lable: "Black kimono", checked: false },
                    { value: "Black Mobius Ring Guards", lable: "Black Mobius Ring Guards", checked: false },
                    { value: "Black shirt with loving heart", lable: "Black shirt with loving heart", checked: false },
                    { value: "Black shoulder armor", lable: "Black shoulder armor", checked: false },
                    { value: "Black singlet", lable: "Black singlet", checked: false },
                    { value: "Black striped casual sweater", lable: "Black striped casual sweater", checked: false },
                    { value: "Black suit", lable: "Black suit", checked: false },
                    { value: "Black Tactical Vest", lable: "Black Tactical Vest", checked: false },
                    { value: "Black vest with red ribbons", lable: "Black vest with red ribbons", checked: false },
                    { value: "Blue basketball suit", lable: "Blue basketball suit", checked: false },
                    { value: "Blue Bib trousers", lable: "Blue Bib trousers", checked: false },
                    { value: "Blue black gentleman suit", lable: "Blue black gentleman suit", checked: false },
                    { value: "Blue casual coat", lable: "Blue casual coat", checked: false },
                    { value: "Blue casual short sleeve", lable: "Blue casual short sleeve", checked: false },
                    { value: "Blue casual sweater", lable: "Blue casual sweater", checked: false },
                    { value: "Blue checked sailor suit", lable: "Blue checked sailor suit", checked: false },
                    { value: "Blue checkered casual coat", lable: "Blue checkered casual coat", checked: false },
                    { value: "Blue diamond check shirt", lable: "Blue diamond check shirt", checked: false },
                    { value: "Blue down jacket", lable: "Blue down jacket", checked: false },
                    { value: "Blue gladiator", lable: "Blue gladiator", checked: false },
                    { value: "Blue Gradient Casual Coat", lable: "Blue Gradient Casual Coat", checked: false },
                    { value: "Blue gradient kimono", lable: "Blue gradient kimono", checked: false },
                    { value: "Blue green down jacket", lable: "Blue green down jacket", checked: false },
                    { value: "Blue grey sailor suit", lable: "Blue grey sailor suit", checked: false },
                    { value: "Blue Guard Battle Suit", lable: "Blue Guard Battle Suit", checked: false },
                    { value: "Blue JK uniform", lable: "Blue JK uniform", checked: false },
                    { value: "Blue kimono", lable: "Blue kimono", checked: false },
                    { value: "blue leaf shirt", lable: "blue leaf shirt", checked: false },
                    { value: "Blue machine armour battle suit", lable: "Blue machine armour battle suit", checked: false },
                    { value: "Blue Magic Academy Clothes", lable: "Blue Magic Academy Clothes", checked: false },
                    { value: "Blue Mobius Ring Guards", lable: "Blue Mobius Ring Guards", checked: false },
                    { value: "Blue nailing battle suit", lable: "Blue nailing battle suit", checked: false },
                    { value: "Blue Naruto", lable: "Blue Naruto", checked: false },
                    { value: "Blue pattern maid dress", lable: "Blue pattern maid dress", checked: false },
                    { value: "Blue samurai suit", lable: "Blue samurai suit", checked: false },
                    { value: "Blue scarf", lable: "Blue scarf", checked: false },
                    { value: "Blue singlet", lable: "Blue singlet", checked: false },
                    { value: "Blue space suit", lable: "Blue space suit", checked: false },
                    { value: "Blue suit", lable: "Blue suit", checked: false },
                    { value: "Blue Textured Samurai Suit", lable: "Blue Textured Samurai Suit", checked: false },
                    { value: "Bruce Lee's Clothes", lable: "Bruce Lee's Clothes", checked: false },
                    { value: "Camouflage tactical vest", lable: "Camouflage tactical vest", checked: false },
                    { value: "Casual short sleeve in flesh color", lable: "Casual short sleeve in flesh color", checked: false },
                    { value: "Checked down jacket", lable: "Checked down jacket", checked: false },
                    { value: "Clothes of pattern attendants", lable: "Clothes of pattern attendants", checked: false },
                    { value: "Clown suit", lable: "Clown suit", checked: false },
                    { value: "College Scarf", lable: "College Scarf", checked: false },
                    { value: "Colorful leaf shirt", lable: "Colorful leaf shirt", checked: false },
                    { value: "Dark blue gentleman suit", lable: "Dark blue gentleman suit", checked: false },
                    { value: "Dark blue suit", lable: "Dark blue suit", checked: false },
                    { value: "Dark maid's dress", lable: "Dark maid's dress", checked: false },
                    { value: "Flame Machine Armor Battle Clothes", lable: "Flame Machine Armor Battle Clothes", checked: false },
                    { value: "Ghost", lable: "Ghost", checked: false },
                    { value: "Green  Guard Battle Suit", lable: "Green  Guard Battle Suit", checked: false },
                    { value: "Green battle suit", lable: "Green battle suit", checked: false },
                    { value: "Green Bib trousers", lable: "Green Bib trousers", checked: false },
                    { value: "green captain's clothes", lable: "green captain's clothes", checked: false },
                    { value: "Green casual coat", lable: "Green casual coat", checked: false },
                    { value: "Green casual short sleeve", lable: "Green casual short sleeve", checked: false },
                    { value: "Green casual sweater", lable: "Green casual sweater", checked: false },
                    { value: "Green checked sailor suit", lable: "Green checked sailor suit", checked: false },
                    { value: "Green down jacket", lable: "Green down jacket", checked: false },
                    { value: "Green gentleman suit", lable: "Green gentleman suit", checked: false },
                    { value: "green ghost", lable: "green ghost", checked: false },
                    { value: "Green jelly suit", lable: "Green jelly suit", checked: false },
                    { value: "Green JK uniform", lable: "Green JK uniform", checked: false },
                    { value: "Green leaf kimono", lable: "Green leaf kimono", checked: false },
                    { value: "Green leaf shirt", lable: "Green leaf shirt", checked: false },
                    { value: "Green Magic Academy Clothes", lable: "Green Magic Academy Clothes", checked: false },
                    { value: "Green Mobius Ring Guards", lable: "Green Mobius Ring Guards", checked: false },
                    { value: "Green polka dot sailor suit", lable: "Green polka dot sailor suit", checked: false },
                    { value: "Green scarf", lable: "Green scarf", checked: false },
                    { value: "Green single coat", lable: "Green single coat", checked: false },
                    { value: "Green smiling face casual sweater", lable: "Green smiling face casual sweater", checked: false },
                    { value: "Green space suit", lable: "Green space suit", checked: false },
                    { value: "Green suit", lable: "Green suit", checked: false },
                    { value: "Green Tactical Vest", lable: "Green Tactical Vest", checked: false },
                    { value: "Green vest with Yellow ribbons", lable: "Green vest with Yellow ribbons", checked: false },
                    { value: "Green Violet down jacket", lable: "Green Violet down jacket", checked: false },
                    { value: "Grey green nail battle suit", lable: "Grey green nail battle suit", checked: false },
                    { value: "Grey shoulder armor", lable: "Grey shoulder armor", checked: false },
                    { value: "Grey tactical vest", lable: "Grey tactical vest", checked: false },
                    { value: "Grey Textured Gentleman", lable: "Grey Textured Gentleman", checked: false },
                    { value: "Ink style shirt", lable: "Ink style shirt", checked: false },
                    { value: "Jujube single coat", lable: "Jujube single coat", checked: false },
                    { value: "Khaki casual sweater", lable: "Khaki casual sweater", checked: false },
                    { value: "Khaki Servant Clothes", lable: "Khaki Servant Clothes", checked: false },
                    { value: "Khaki suit", lable: "Khaki suit", checked: false },
                    { value: "leaf texture maid dress", lable: "leaf texture maid dress", checked: false },
                    { value: "Light blue casual coat", lable: "Light blue casual coat", checked: false },
                    { value: "Light color machine armour battle suit", lable: "Light color machine armour battle suit", checked: false },
                    { value: "Light Gradient Casual Coat", lable: "Light Gradient Casual Coat", checked: false },
                    { value: "Macaque style suit", lable: "Macaque style suit", checked: false },
                    { value: "Mechanical arm jacket", lable: "Mechanical arm jacket", checked: false },
                    { value: "Naruto", lable: "Naruto", checked: false },
                    { value: "Orange bib pants", lable: "Orange bib pants", checked: false },
                    { value: "Orange casual coat", lable: "Orange casual coat", checked: false },
                    { value: "Orange down jacket", lable: "Orange down jacket", checked: false },
                    { value: "Orange guard battle suit", lable: "Orange guard battle suit", checked: false },
                    { value: "Orange JK uniform", lable: "Orange JK uniform", checked: false },
                    { value: "Orange machine armour battle suit", lable: "Orange machine armour battle suit", checked: false },
                    { value: "Orange nail battle suit", lable: "Orange nail battle suit", checked: false },
                    { value: "Orange Naruto", lable: "Orange Naruto", checked: false },
                    { value: "Orange space suit", lable: "Orange space suit", checked: false },
                    { value: "Orange white battle suit", lable: "Orange white battle suit", checked: false },
                    { value: "Panther Pattern Attendant Clothes", lable: "Panther Pattern Attendant Clothes", checked: false },
                    { value: "Pink Gentleman", lable: "Pink Gentleman", checked: false },
                    { value: "Pink maid's dress", lable: "Pink maid's dress", checked: false },
                    { value: "Pink Samurai Suit", lable: "Pink Samurai Suit", checked: false },
                    { value: "Pink Textured Kimono", lable: "Pink Textured Kimono", checked: false },
                    { value: "Plaid casual coat", lable: "Plaid casual coat", checked: false },
                    { value: "Purple basketball suit", lable: "Purple basketball suit", checked: false },
                    { value: "Purple captain's clothes", lable: "Purple captain's clothes", checked: false },
                    { value: "Purple casual coat", lable: "Purple casual coat", checked: false },
                    { value: "Purple casual short sleeve", lable: "Purple casual short sleeve", checked: false },
                    { value: "Purple casual sweater", lable: "Purple casual sweater", checked: false },
                    { value: "Purple checked battle suit", lable: "Purple checked battle suit", checked: false },
                    { value: "Purple Combat Vest", lable: "Purple Combat Vest", checked: false },
                    { value: "purple Ghost", lable: "purple Ghost", checked: false },
                    { value: "Purple Gladiator suit", lable: "Purple Gladiator suit", checked: false },
                    { value: "Purple Gradient Casual Coat", lable: "Purple Gradient Casual Coat", checked: false },
                    { value: "Purple Guard Battle Suit", lable: "Purple Guard Battle Suit", checked: false },
                    { value: "Purple jelly suit", lable: "Purple jelly suit", checked: false },
                    { value: "Purple machine armour battle suit", lable: "Purple machine armour battle suit", checked: false },
                    { value: "Purple red suit", lable: "Purple red suit", checked: false },
                    { value: "Purple ribbon leopard print vest", lable: "Purple ribbon leopard print vest", checked: false },
                    { value: "Purple samurai suit", lable: "Purple samurai suit", checked: false },
                    { value: "Purple smiling face casual sweater", lable: "Purple smiling face casual sweater", checked: false },
                    { value: "Red basketball suit", lable: "Red basketball suit", checked: false },
                    { value: "Red battle suit", lable: "Red battle suit", checked: false },
                    { value: "Red blue down jacket", lable: "Red blue down jacket", checked: false },
                    { value: "Red captain's clothes", lable: "Red captain's clothes", checked: false },
                    { value: "Red casual short sleeve", lable: "Red casual short sleeve", checked: false },
                    { value: "Red Cloud Robe with Black Background", lable: "Red Cloud Robe with Black Background", checked: false },
                    { value: "Red Combat Vest", lable: "Red Combat Vest", checked: false },
                    { value: "Red Ghost", lable: "Red Ghost", checked: false },
                    { value: "Red gladiator suit", lable: "Red gladiator suit", checked: false },
                    { value: "Red jelly suit", lable: "Red jelly suit", checked: false },
                    { value: "Red JK uniform", lable: "Red JK uniform", checked: false },
                    { value: "Red machine armour battle suit", lable: "Red machine armour battle suit", checked: false },
                    { value: "Red Magic Academy Clothes", lable: "Red Magic Academy Clothes", checked: false },
                    { value: "_Red ribbon Red vest", lable: "_Red ribbon Red vest", checked: false },
                    { value: "Red Ribbon Sun Tank Top", lable: "Red Ribbon Sun Tank Top", checked: false },
                    { value: "Red samurai suit", lable: "Red samurai suit", checked: false },
                    { value: "Red scarf", lable: "Red scarf", checked: false },
                    { value: "Red smiley face casual sweater", lable: "Red smiley face casual sweater", checked: false },
                    { value: "Red suit", lable: "Red suit", checked: false },
                    { value: "Rose red down jacket", lable: "Rose red down jacket", checked: false },
                    { value: "Rose tactical vest", lable: "Rose tactical vest", checked: false },
                    { value: "Sky Blue JK uniform", lable: "Sky Blue JK uniform", checked: false },
                    { value: "Spirited Away", lable: "Spirited Away", checked: false },
                    { value: "Sun Guard battle suit", lable: "Sun Guard battle suit", checked: false },
                    { value: "Tawny gladiator suit", lable: "Tawny gladiator suit", checked: false },
                    { value: "Tawny retinue clothes", lable: "Tawny retinue clothes", checked: false },
                    { value: "Tawny shoulder armor", lable: "Tawny shoulder armor", checked: false },
                    { value: "Tea grey sailor suit", lable: "Tea grey sailor suit", checked: false },
                    { value: "Thieves' clothes", lable: "Thieves' clothes", checked: false },
                    { value: "Tiger Veins Nezha", lable: "Tiger Veins Nezha", checked: false },
                    { value: "Turquoise single", lable: "Turquoise single", checked: false },
                    { value: "Violet casual coat", lable: "Violet casual coat", checked: false },
                    { value: "Violet Mobius Ring Guards", lable: "Violet Mobius Ring Guards", checked: false },
                    { value: "Violet space suit", lable: "Violet space suit", checked: false },
                    { value: "white attendant clothes", lable: "white attendant clothes", checked: false },
                    { value: "White Bib trousers", lable: "White Bib trousers", checked: false },
                    { value: "White Combat Vest", lable: "White Combat Vest", checked: false },
                    { value: "White maid dress", lable: "White maid dress", checked: false },
                    { value: "White shirt with loving heart", lable: "White shirt with loving heart", checked: false },
                    { value: "Yellow  guard battle suit", lable: "Yellow  guard battle suit", checked: false },
                    { value: "Yellow and black sailor suit", lable: "Yellow and black sailor suit", checked: false },
                    { value: "Yellow basketball suit", lable: "Yellow basketball suit", checked: false },
                    { value: "Yellow casual short sleeve", lable: "Yellow casual short sleeve", checked: false },
                    { value: "Yellow Combat Vest", lable: "Yellow Combat Vest", checked: false },
                    { value: "Yellow down jacket", lable: "Yellow down jacket", checked: false },
                    { value: "Yellow Gladiator suit", lable: "Yellow Gladiator suit", checked: false },
                    { value: "Yellow green down jacket", lable: "Yellow green down jacket", checked: false },
                    { value: "Yellow Magic Academy Clothes", lable: "Yellow Magic Academy Clothes", checked: false },
                    { value: "Yellow Mobius Ring Guards", lable: "Yellow Mobius Ring Guards", checked: false },
                    { value: "Yellow nail battle suit", lable: "Yellow nail battle suit", checked: false },
                    { value: "Yellow samurai suit", lable: "Yellow samurai suit", checked: false }
                ]
            },
            {
                icon: 'icon3.png',
                title: 'HANDHOLD',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Bitcoin", lable: "Bitcoin", checked: false },
                    { value: "Cash", lable: "Cash", checked: false },
                    { value: "Submachine gun", lable: "Submachine gun", checked: false },
                    { value: "Captain's hand", lable: "Captain's hand", checked: false },
                    { value: "Cake", lable: "Cake", checked: false },
                    { value: "Poison", lable: "Poison", checked: false },
                    { value: "Basketball", lable: "Basketball", checked: false },
                    { value: "Wolverine stick", lable: "Wolverine stick", checked: false },
                    { value: "Spiral pills", lable: "Spiral pills", checked: false },
                    { value: "Mushroom elf", lable: "Mushroom elf", checked: false },
                    { value: "Nezha Gun", lable: "Nezha Gun", checked: false },
                    { value: "Bat", lable: "Bat", checked: false },
                    { value: "Bladefist", lable: "Bladefist", checked: false },
                    { value: "Umbrella", lable: "Umbrella", checked: false },
                    { value: "Revolver", lable: "Revolver", checked: false },
                    { value: "Ethereum", lable: "Ethereum", checked: false },
                    { value: "Bio gun", lable: "Bio gun", checked: false },
                    { value: "Blade", lable: "Blade", checked: false },
                    { value: "Money-printing gun", lable: "Money-printing gun", checked: false }
                ]
            },
            {
                icon: 'icon4.png',
                title: 'HAIR',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Black Dreadlocks", lable: "Black Dreadlocks", checked: false },
                    { value: "Black Fox's ears", lable: "Black Fox's ears", checked: false },
                    { value: "Black Nezha", lable: "Black Nezha", checked: false },
                    { value: "Black Spirited Away", lable: "Black Spirited Away", checked: false },
                    { value: "Black Wolf-Cut hair", lable: "Black Wolf-Cut hair", checked: false },
                    { value: "Blue Bun hair", lable: "Blue Bun hair", checked: false },
                    { value: "Blue Dynamic split hair style", lable: "Blue Dynamic split hair style", checked: false },
                    { value: "Blue Fox's ears", lable: "Blue Fox's ears", checked: false },
                    { value: "Blue gradient Dreadlocks", lable: "Blue gradient Dreadlocks", checked: false },
                    { value: "Blue Large dorsal head", lable: "Blue Large dorsal head", checked: false },
                    { value: "Blue Nezha", lable: "Blue Nezha", checked: false },
                    { value: "Blue One-nine side part hair", lable: "Blue One-nine side part hair", checked: false },
                    { value: "Blue Ponytail", lable: "Blue Ponytail", checked: false },
                    { value: "Blue Side back hairstyle", lable: "Blue Side back hairstyle", checked: false },
                    { value: "Blue Spirited Away", lable: "Blue Spirited Away", checked: false },
                    { value: "Coffee Dreadlocks", lable: "Coffee Dreadlocks", checked: false },
                    { value: "Coffee Samurai hairstyle", lable: "Coffee Samurai hairstyle", checked: false },
                    { value: "Coffee Side back hairstyle", lable: "Coffee Side back hairstyle", checked: false },
                    { value: "Coffee Spirited Away", lable: "Coffee Spirited Away", checked: false },
                    { value: "Grass green Nezha", lable: "Grass green Nezha", checked: false },
                    { value: "Green Dynamic split hair style", lable: "Green Dynamic split hair style", checked: false },
                    { value: "Green Feminine punk", lable: "Green Feminine punk", checked: false },
                    { value: "Green Fox's ears", lable: "Green Fox's ears", checked: false },
                    { value: "Green Neck-length hair", lable: "Green Neck-length hair", checked: false },
                    { value: "Green Ponytail", lable: "Green Ponytail", checked: false },
                    { value: "Green Samurai hairstyle", lable: "Green Samurai hairstyle", checked: false },
                    { value: "Green Side back hairstyle", lable: "Green Side back hairstyle", checked: false },
                    { value: "Grey Bun hair", lable: "Grey Bun hair", checked: false },
                    { value: "grey Samurai hairstyle", lable: "grey Samurai hairstyle", checked: false },
                    { value: "grey Side back hairstyle", lable: "grey Side back hairstyle", checked: false },
                    { value: "Grey Wolf-Cut hair", lable: "Grey Wolf-Cut hair", checked: false },
                    { value: "GreyFox's ears", lable: "GreyFox's ears", checked: false },
                    { value: "Lavender Bun hair", lable: "Lavender Bun hair", checked: false },
                    { value: "Lavender Dreadlocks", lable: "Lavender Dreadlocks", checked: false },
                    { value: "light colour One-nine side part hair", lable: "light colour One-nine side part hair", checked: false },
                    { value: "Light green Dreadlocks", lable: "Light green Dreadlocks", checked: false },
                    { value: "Navy blue Fox's ears", lable: "Navy blue Fox's ears", checked: false },
                    { value: "Navy blue Samurai hairstyle", lable: "Navy blue Samurai hairstyle", checked: false },
                    { value: "Orange Fox's ears", lable: "Orange Fox's ears", checked: false },
                    { value: "Orange One-nine side part hair", lable: "Orange One-nine side part hair", checked: false },
                    { value: "Orange Ponytail", lable: "Orange Ponytail", checked: false },
                    { value: "Pink Dreadlocks", lable: "Pink Dreadlocks", checked: false },
                    { value: "Pink Large dorsal head", lable: "Pink Large dorsal head", checked: false },
                    { value: "Pink Nezha", lable: "Pink Nezha", checked: false },
                    { value: "Pink One-nine side part hair", lable: "Pink One-nine side part hair", checked: false },
                    { value: "Pink Spirited Away", lable: "Pink Spirited Away", checked: false },
                    { value: "Purple Bun hair", lable: "Purple Bun hair", checked: false },
                    { value: "Purple Dreadlocks", lable: "Purple Dreadlocks", checked: false },
                    { value: "Purple Feminine punk", lable: "Purple Feminine punk", checked: false },
                    { value: "Purple Neck-length hair", lable: "Purple Neck-length hair", checked: false },
                    { value: "Purple One-nine side part hair", lable: "Purple One-nine side part hair", checked: false },
                    { value: "Purple Ponytail", lable: "Purple Ponytail", checked: false },
                    { value: "Purple Side back hairstyle", lable: "Purple Side back hairstyle", checked: false },
                    { value: "Purple Spirited Away", lable: "Purple Spirited Away", checked: false },
                    { value: "Purple Wolf-Cut hair", lable: "Purple Wolf-Cut hair", checked: false },
                    { value: "Red Bun hair", lable: "Red Bun hair", checked: false },
                    { value: "Red Dynamic split hair style", lable: "Red Dynamic split hair style", checked: false },
                    { value: "Red Neck-length hair", lable: "Red Neck-length hair", checked: false },
                    { value: "Red Wolf-Cut hair", lable: "Red Wolf-Cut hair", checked: false },
                    { value: "Rose red Dreadlocks", lable: "Rose red Dreadlocks", checked: false },
                    { value: "Sun Wolf-Cut hair", lable: "Sun Wolf-Cut hair", checked: false },
                    { value: "Turquoise Neck-length hair", lable: "Turquoise Neck-length hair", checked: false },
                    { value: "Turquoise Ponytail", lable: "Turquoise Ponytail", checked: false },
                    { value: "Turquoise Samurai hairstyle", lable: "Turquoise Samurai hairstyle", checked: false },
                    { value: "purple Nezha", lable: "purple Nezha", checked: false },
                    { value: "Violet Feminine punk", lable: "Violet Feminine punk", checked: false },
                    { value: "Violet gradient Dreadlocks", lable: "Violet gradient Dreadlocks", checked: false },
                    { value: "Violet Robopunk", lable: "Violet Robopunk", checked: false },
                    { value: "White Dreadlocks", lable: "White Dreadlocks", checked: false },
                    { value: "White Dynamic split hair style", lable: "White Dynamic split hair style", checked: false },
                    { value: "White Feminine punk", lable: "White Feminine punk", checked: false },
                    { value: "White Spirited Away", lable: "White Spirited Away", checked: false },
                    { value: "Yellow Bun hair", lable: "Yellow Bun hair", checked: false },
                    { value: "Yellow Dynamic split hair style", lable: "Yellow Dynamic split hair style", checked: false },
                    { value: "Yellow gradient Dreadlocks", lable: "Yellow gradient Dreadlocks", checked: false },
                    { value: "Yellow Ponytail", lable: "Yellow Ponytail", checked: false },
                    { value: "Yellow Robopunk", lable: "Yellow Robopunk", checked: false },
                    { value: "Yellow Samurai hairstyle", lable: "Yellow Samurai hairstyle", checked: false },
                    { value: "Yellow Side back hairstyle", lable: "Yellow Side back hairstyle", checked: false },
                    { value: "Black Beef bones", lable: "Black Beef bones", checked: false }
                ]
            },
            {
                icon: 'icon5.png',
                title: 'HEADWEAR',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Black small size wool cap", lable: "Black small size wool cap", checked: false },
                    { value: "Blue striped underpants", lable: "Blue striped underpants", checked: false },
                    { value: "Brainstorm", lable: "Brainstorm", checked: false },
                    { value: "Cash", lable: "Cash", checked: false },
                    { value: "Color striped wool cap", lable: "Color striped wool cap", checked: false },
                    { value: "Green  small size wool cap", lable: "Green  small size wool cap", checked: false },
                    { value: "Mushroom", lable: "Mushroom", checked: false },
                    { value: "Orange striped wool cap", lable: "Orange striped wool cap", checked: false },
                    { value: "Pink underpants", lable: "Pink underpants", checked: false },
                    { value: "Purple Hip Hop", lable: "Purple Hip Hop", checked: false },
                    { value: "Red  small size wool cap", lable: "Red  small size wool cap", checked: false },
                    { value: "Red Beef bones", lable: "Red Beef bones", checked: false },
                    { value: "Red Captain's hat", lable: "Red Captain's hat", checked: false },
                    { value: "Red Hip Hop", lable: "Red Hip Hop", checked: false },
                    { value: "Red Straw hat", lable: "Red Straw hat", checked: false },
                    { value: "Red striped wool cap", lable: "Red striped wool cap", checked: false },
                    { value: "Red Woolen hat", lable: "Red Woolen hat", checked: false },
                    { value: "White Beef bones", lable: "White Beef bones", checked: false },
                    { value: "White striped wool cap", lable: "White striped wool cap", checked: false },
                    { value: "Yellow Captain's hat", lable: "Yellow Captain's hat", checked: false },
                    { value: "Yellow Hip Hop", lable: "Yellow Hip Hop", checked: false },
                    { value: "Yellow small size wool cap", lable: "Yellow small size wool cap", checked: false },
                    { value: "Yellow Straw hat", lable: "Yellow Straw hat", checked: false },
                    { value: "Yellow striped wool cap", lable: "Yellow striped wool cap", checked: false },
                    { value: "Yellow Woolen hat", lable: "Yellow Woolen hat", checked: false }
                ]
            },
            {
                icon: 'icon6.png',
                title: 'SMALL PROPS',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Blue Shell", lable: "Blue Shell", checked: false },
                    { value: "Chain", lable: "Chain", checked: false },
                    { value: "Cowboy hat", lable: "Cowboy hat", checked: false },
                    { value: "Cupid's arrow", lable: "Cupid's arrow", checked: false },
                    { value: "Fish", lable: "Fish", checked: false },
                    { value: "Flamingo Swimming ring", lable: "Flamingo Swimming ring", checked: false },
                    { value: "green Bubble", lable: "green Bubble", checked: false },
                    { value: "Green Clockwork", lable: "Green Clockwork", checked: false },
                    { value: "Green eye Zip", lable: "Green eye Zip", checked: false },
                    { value: "Green Fish hat", lable: "Green Fish hat", checked: false },
                    { value: "Green Tight band", lable: "Green Tight band", checked: false },
                    { value: "Grey Clockwork", lable: "Grey Clockwork", checked: false },
                    { value: "Orange Fish hat", lable: "Orange Fish hat", checked: false },
                    { value: "Pink Bubble", lable: "Pink Bubble", checked: false },
                    { value: "Pink Shell", lable: "Pink Shell", checked: false },
                    { value: "purple Bubble", lable: "purple Bubble", checked: false },
                    { value: "Purple duck Swimming ring", lable: "Purple duck Swimming ring", checked: false },
                    { value: "PurpleTight band", lable: "PurpleTight band", checked: false },
                    { value: "Red eye Zip", lable: "Red eye Zip", checked: false },
                    { value: "Toilet plugs", lable: "Toilet plugs", checked: false },
                    { value: "Yellow Clockwork", lable: "Yellow Clockwork", checked: false },
                    { value: "Yellow duck Swimming ring", lable: "Yellow duck Swimming ring", checked: false },
                    { value: "Yellow eye Zip", lable: "Yellow eye Zip", checked: false },
                    { value: "Yellow Fish hat", lable: "Yellow Fish hat", checked: false },
                    { value: "Yellow Shell", lable: "Yellow Shell", checked: false },
                    { value: "Yellow Tight band", lable: "Yellow Tight band", checked: false }
                ]
            },
            {
                icon: 'icon7.png',
                title: 'MOUTH',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Cigar", lable: "Cigar", checked: false },
                    { value: "Golden Teeth", lable: "Golden Teeth", checked: false },
                    { value: "Lollipop", lable: "Lollipop", checked: false },
                    { value: "Laugh", lable: "Laugh", checked: false },
                    { value: "Rage", lable: "Rage", checked: false },
                    { value: "Red lip", lable: "Red lip", checked: false },
                    { value: "shout", lable: "shout", checked: false },
                    { value: "Silence", lable: "Silence", checked: false },
                    { value: "Stick out tongue", lable: "Stick out tongue", checked: false }
                ]
            },
            {
                icon: 'icon8.png',
                title: 'EYES',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Blind", lable: "Blind", checked: false },
                    { value: "Bitcoin", lable: "Bitcoin", checked: false },
                    { value: "Glass", lable: "Glass", checked: false },
                    { value: "Supercilious", lable: "Supercilious", checked: false },
                    { value: "Cute", lable: "Cute", checked: false },
                    { value: "Angry", lable: "Angry", checked: false },
                    { value: "Concern", lable: "Concern", checked: false },
                    { value: "Contemptuous", lable: "Contemptuous", checked: false },
                    { value: "Charming", lable: "Charming", checked: false },
                    { value: "Cyberpunk glasses", lable: "Cyberpunk glasses", checked: false },
                    { value: "Cyberpunk Eye Mask", lable: "Cyberpunk Eye Mask", checked: false },
                    { value: "Ferocious eyes", lable: "Ferocious eyes", checked: false },
                    { value: "Love Pirate Eye Mask", lable: "Love Pirate Eye Mask", checked: false },
                    { value: "Black pirate eye mask", lable: "Black pirate eye mask", checked: false },
                    { value: "Skull Pirate Eye Mask", lable: "Skull Pirate Eye Mask", checked: false },
                    { value: "Tan pirate eye mask", lable: "Tan pirate eye mask", checked: false },
                    { value: "Yellow pirate eye mask", lable: "Yellow pirate eye mask", checked: false }
                ]
            },
            {
                icon: 'icon9.png',
                title: 'MOUTH ORNAMENT',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Red Machine masks", lable: "Red Machine masks", checked: false },
                    { value: "Black Machine masks", lable: "Black Machine masks", checked: false },
                    { value: "Cigar", lable: "Cigar", checked: false },
                    { value: "Purple mask", lable: "Purple mask", checked: false },
                    { value: "Plaid mask", lable: "Plaid mask", checked: false },
                    { value: "Orange mask", lable: "Orange mask", checked: false },
                    { value: "Green mask", lable: "Green mask", checked: false },
                    { value: "Blue mask", lable: "Blue mask", checked: false },
                    { value: "Black mask", lable: "Black mask", checked: false },
                    { value: "Black beard", lable: "Black beard", checked: false },
                    { value: "Purple beard", lable: "Purple beard", checked: false },
                    { value: "Blue beard", lable: "Blue beard", checked: false },
                    { value: "Pink beard", lable: "Pink beard", checked: false },
                    { value: "Tawny beard", lable: "Tawny beard", checked: false }
                ]
            },
            {
                icon: 'icon10.png',
                title: 'FEATURES',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "Heart", lable: "Heart", checked: false },
                    { value: "Scar", lable: "Scar", checked: false },
                    { value: "Pirate", lable: "Pirate", checked: false },
                    { value: "Thief", lable: "Thief", checked: false },
                    { value: "Tatoo", lable: "Tatoo", checked: false },
                    { value: "Tattoo-B", lable: "Tattoo-B", checked: false },
                    { value: "Head circuit", lable: "Head circuit", checked: false },
                    { value: "Ear circuit", lable: "Ear circuit", checked: false },
                    { value: "Brain circuit", lable: "Brain circuit", checked: false },
                    { value: "Joker", lable: "Joker", checked: false }
                ]
            },
            {
                icon: 'icon11.png',
                title: 'SPECIAL EFFECTS',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: 'Light particles', lable: 'Light particles', checked: false},
                    { value: 'Lightning', lable: 'Lightning', checked: false},
                    { value: 'Raining', lable: 'Raining', checked: false}
                ]
            },
            {
                icon: 'icon12.png',
                title: 'BACKGROUND',
                isOpen: false,
                selected: [],
                allSelect: [
                    { value: "School", lable: "School", checked: false },
                    { value: "Forest", lable: "Forest", checked: false },
                    { value: "A_written words", lable: "A_written words", checked: false },
                    { value: "A_written words_a", lable: "A_written words_a", checked: false },
                    { value: "Bamboo", lable: "Bamboo", checked: false },
                    { value: "Blue", lable: "Blue", checked: false },
                    { value: "Purple", lable: "Purple", checked: false },
                    { value: "Orange", lable: "Orange", checked: false },
                    { value: "Gradient color", lable: "Gradient color", checked: false }
                ]
            },
        ],
        radioData: [
            { value: 'EYES', lable: 'EYES' },
            { value: 'MOUTH', lable: 'MOUTH' },
            { value: 'EAR', lable: 'EAR' },
            { value: 'BACKGROUND', lable: 'BACKGROUND' },
            { value: 'NECK ', lable: 'NECK' },
        ]
    }
    
    componentDidMount() {
        document.getElementById('imgScroll').addEventListener('scroll', this.antiShake(this.handleScroll, 1000))
        this.getImgList(0)
        this.getImgList(1)
    }
    componentWillUnmount() {
        document.getElementById('imgScroll').removeEventListener('scroll', this.handleScroll);
    }

    // 模拟初始化图片数据
    mockImgData = (index) =>{
        switch(index){
            case 0: 
                return {
                    data: ['1.png', '2.png', '3.png', '4.png','5.png', '6.png', '7.png', '8.png', '9.png', '10.png',
                    '1.png', '2.png', '3.png', '4.png','5.png', '6.png', '7.png', '8.png', '9.png', '10.png'],
                    total: 88,
                }
            case 1: 
                return {
                    data: ['5.png', '6.png', '7.png', '8.png','9.png', '10.png', '11.png', '12.png', '1.png', '2.png',
                    '3.png', '4.png','5.png', '6.png', '7.png', '8.png', '9.png', '10.png','11.png', '12.png'],
                    total: 92,
                }
        }
    }

    antiShake(fn, wait) {
        let timer
        return () => {
            clearTimeout(timer)
            timer = setTimeout(fn, wait)
        }
    }

    handleScroll = ()=> {
        const { currentIndex} = this.state,
              element = document.getElementById(`loading${currentIndex}`),
              elementHeight = element.clientHeight,
              elementOffsetTop = element.offsetTop,
              scrollBoxHeight = document.getElementById('imgScroll').clientHeight,
              scrollBoxTop = document.getElementById('imgScroll').scrollTop

        if ((scrollBoxTop <= elementOffsetTop + elementHeight) && (scrollBoxTop >= elementOffsetTop - scrollBoxHeight)) {
            console.log('滚动到底部加载数据')

            this.setState(state =>{
                let { tabData, currentIndex} = state, num = parseInt(this.mockImgData(currentIndex).total/tabData[currentIndex].pageSize)

                if(tabData[currentIndex].total < tabData[currentIndex].pageSize){
                    tabData[currentIndex].showLoading = false
                }
                if(tabData[currentIndex].total > tabData[currentIndex].pageSize){
                    if(tabData[currentIndex].pageNum < num){
                        tabData[currentIndex].pageNum ++
                        tabData[currentIndex].showLoading = true
                        if(currentIndex === 0){
                            tabData[currentIndex].imgUrl.push('1.png', '1.png', '1.png', '1.png','1.png', '1.png', '1.png', '1.png', '1.png', '1.png',
                            '1.png', '1.png', '1.png', '1.png','1.png', '1.png', '1.png', '1.png', '1.png', '1.png')
                        }
                        if(currentIndex === 1){
                            tabData[currentIndex].imgUrl.push('2.png', '2.png', '2.png', '2.png','2.png', '2.png', '2.png', '2.png', '2.png', '2.png',
                            '2.png', '2.png', '2.png', '2.png','2.png', '2.png', '2.png', '2.png', '2.png', '2.png')
                        }
                    }else{
                        tabData[currentIndex].showLoading = false
                    }
                }
                return state
            })
        }
    }


    // 侧边栏条件展开
    handleTitleSwitch(index){
        this.setState(state => {  
            return { 
                currentFilterIndex : index, 
                listFilterData: state.listFilterData.map((item, key)=> 
                key === index ? {...item, isOpen: !item.isOpen} : item)
            }
         }, () => {  
            console.log('点击侧边栏', this.state.listFilterData)
        })
    }

    // 多选按钮
    onCheckChange = (e, index, checkIndex) => {
        console.log(e.target)
        let obj = JSON.parse(JSON.stringify(this.state.listFilterData))
        obj[index].allSelect[checkIndex].checked = !obj[index].allSelect[checkIndex].checked
        e.target.checked ? obj[index].selected.push(e.target.value) : obj[index].selected.pop()
        this.setState(state => {
            return {
                listFilterData: obj
            }
        }, () => {
            console.log('侧边栏勾选框的值selected属性', this.state.listFilterData)
        })
    };

    // 单选按钮
    onRaioChange = (e) => {
        console.log('侧边栏单选按钮值', e.target.value)
    }

    // 重置
    resetFilter = () => {
        let list = this.state.listFilterData
        for(let i = 0; i < list.length; i ++){
            for(let j = 0; j < list[i].allSelect.length; j ++){
                list[i].allSelect[j].checked = false
            }
        }
        this.setState(state => {  
            return { 
                listFilterData: list
            }
         }, () => {  
            console.log('重置完成', this.state.listFilterData)
        })
    }

    // 单选按钮展开
    openRadioBox = () => {
        let { showRadioBox } = this.state
        this.setState({
            showRadioBox: !showRadioBox
        })
    }

    getImgList = (current) => {
        this.setState(state =>{
            let { tabData } = state,
                num = parseInt(this.mockImgData(current).total/tabData[current].pageSize)
            tabData[current].total = this.mockImgData(current).total
            tabData[current].imgUrl.push(...this.mockImgData(current).data)

            if(tabData[current].total < tabData[current].pageSize){
                tabData[current].showLoading = false
            }
            if(tabData[current].total > tabData[current].pageSize){
                if(tabData[current].pageNum < num){
                    tabData[current].showLoading = true
                }
            }
            return state
        })
    }

    handleTabSwitch = (index) => {
        document.getElementById('imgScroll').scrollTop = 0
        this.setState({ currentIndex : index })
    }

    // 移动端
    handleOpenModal = () => {
        this.setState({ showModal : true })
    }
    handleOkModal = () => {
        this.setState({ showModal : false })
    }
    handleCancelModal = () => {
        this.setState({ showModal : false })
    }

    render() {
        let { currentIndex,  tabData, listFilterData, showModal } = this.state  // showRadioBox, radioData, 
        return (
            <div className="gallary-page">
                <div className="gallary-title Horizontal_Layout"> 
                    <span className='filter hidden-xs'>FILTER</span>
                    <div>
                        {
                            tabData.map((item, index) => (
                                <div onClick={ () => this.handleTabSwitch(index) } key={index}
                                    className={ `tab-title ${index === currentIndex ? 'active' : ''}` }>
                                    <img src={require( `@/assets/images/${index === currentIndex ? item.titleActive : item.title}`)} alt="" />
                                </div>
                            ))
                        }
                    </div>
                    <span className='filter visible-xs' onClick={this.handleOpenModal} >FILTER<DownOutlined /></span>
                    <Modal  maskTransitionName="" transitionName=""
                    className='filter-modal' title="Basic Modal" open={showModal} onOk={this.handleOkModal} onCancel={this.handleCancelModal}>
                            <ul className='checkBox'>
                                {
                                    listFilterData.map((item, index) => (
                                        <li key={index}>
                                            <div className='gallary-filter-title Horizontal_Layout' 
                                                    onClick={ () => this.handleTitleSwitch(index) } >
                                                <div>
                                                    <span>{item.title}</span>
                                                </div>
                                                <UpOutlined className={`arrow ${item.isOpen ? '' : 'hide'}`}/>
                                                <DownOutlined className={`arrow ${item.isOpen ? 'hide' : ''}`}/>
                                            </div>
                                            <div className={ `gallary-page-l-check ${item.isOpen ? 'active' : ''}`}>
                                                {
                                                    item.allSelect.map((check, checkIndex) => (
                                                        <Checkbox value={check.value} key={checkIndex}
                                                            checked={check.checked}
                                                            onChange={e => this.onCheckChange(e, index, checkIndex)}>
                                                            {check.lable}
                                                        </Checkbox>  
                                                    ))
                                                }                                                  
                                                
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className='reset-btn'  onClick={ () => this.resetFilter() }>
                                SESET FILTERS
                            </div>
                            {/* <div className='tag-select'>
                                <div className='gallary-filter-title Horizontal_Layout' onClick={ () => this.openRadioBox() }>
                                    <div>
                                        <span>EAR</span>
                                    </div>
                                    <UpOutlined className={`arrow ${showRadioBox ? '' : 'hide'}`}/>
                                    <DownOutlined className={`arrow ${showRadioBox ? 'hide' : ''}`}/>
                                </div>
                                <Radio.Group onChange={(e) => this.onRaioChange(e) } className={`tag-select-radio ${showRadioBox ? '' : 'hide'}`}>
                                    <Space direction="vertical">
                                        {
                                            radioData.map((item, index) => (  
                                                <Radio value={item.value} key={index}>{item.lable}</Radio>
                                            ))
                                        }
                                    </Space>
                                </Radio.Group>
                            </div> */}
                        </Modal>
                </div>
                <div className='Horizontal_Layout'>
                    <div className="gallary-page-l hidden-xs">
                        <ul className='checkBox'>
                            {
                                listFilterData.map((item, index) => (
                                    <li key={index}>
                                        <div className='gallary-filter-title Horizontal_Layout' 
                                                onClick={ () => this.handleTitleSwitch(index) } >
                                            <div>
                                                <img  src={require( `@/assets/images/${item.icon}`)} alt="" />
                                                <span>{item.title}</span>
                                            </div>
                                            <img className='arrow' src={item.isOpen ? Up : Down} alt="" />
                                        </div>
                                        <div className={ `gallary-page-l-check ${item.isOpen ? 'active' : ''}`}>
                                            {
                                                item.allSelect.map((check, checkIndex) => (
                                                        <Checkbox value={check.value} key={checkIndex}
                                                            checked={check.checked}
                                                            onChange={e => this.onCheckChange(e, index, checkIndex)}>
                                                            {check.lable}
                                                        </Checkbox>
                                                    )
                                                )
                                            } 
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className='reset-btn'  onClick={ () => this.resetFilter() }>
                            SESET FILTERS
                        </div>
                        {/* <div className='tag-select'>
                            <div className='gallary-filter-title Horizontal_Layout' onClick={ () => this.openRadioBox() }>
                                <div>
                                    <img  src={Tu} alt="" />
                                    <span>EAR</span>
                                </div>
                                <img className='arrow' src={showRadioBox ? TuArrowUp : TuArrowDown} alt="" />
                            </div>
                            <Radio.Group onChange={(e) => this.onRaioChange(e) } className={`tag-select-radio ${showRadioBox ? '' : 'hide'}`}>
                                <Space direction="vertical">
                                    {
                                        radioData.map((item, index) => (  
                                            <Radio value={item.value} key={index}>{item.lable}</Radio>
                                        ))
                                    }
                                </Space>
                            </Radio.Group>
                        </div> */}
                    </div>
                    <div className="gallary-page-r" id='imgScroll'>
                        <img className='gallary-page-r-banner' src={Banner} alt="" />
                        {
                            tabData.map((item, index) => (  
                                <div className={ `tab-content ${index === currentIndex ? 'active' : ''}` } key={index}>
                                    <div className='tab-content-imgBox'>
                                        {
                                            item.imgUrl.map((img, imgIndex) => (
                                                <img src={require( `@/assets/images/${img}`)} alt="" key={imgIndex}/>
                                            ))
                                        }
                                    </div>
                                    <div id={`loading${index}`}>
                                        <div className={`no-more ${item.showLoading ? 'hide' : ''}`}>暂时没有更多了~~</div>
                                        <div className={`loading ${item.showLoading ? '' : 'hide'}`}>
                                            <div className="item"></div>
                                            <div className="item"></div>
                                            <div className="item"></div>
                                            <div className="item"></div>
                                        </div>
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}